import "./libs";
import "./home";

$(function () {

    //menu
    $(".main_header_nav_mobile").on("click", function (e) {
        e.preventDefault();
        $(".main_header_nav nav").fadeIn(1).animate({"right": "0"}, 200).css("left", "auto");
    });

    $(".main_header_nav_close").on("click", function (e) {
        e.preventDefault();
        $(".main_header_nav nav").animate({"left": "100%"}, 200, function () {
            $(".main_header_nav nav").css({"right": "auto", "display": "none"});
        });
    });


    const swiper = new Swiper('.swiper', {
        // loop: true
    });

    var swiperImages = new Swiper(".swiper_images", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    $('.contact-form').on('submit', function (e) {
        e.preventDefault();
        var base = $("meta[name=base]").attr("content");

        $.ajax({
            type: "POST",
            url: base + 'contato',
            dataType: 'JSON',
            data: $(this).serialize(),
            beforeSend: function () {
                $(".ajax_load").fadeIn(200).css("display", "flex");
            },
            success: function (res) {

                if (res.success) {
                    $('.contact-form input[name!=action]').val('');
                    $('.contact-form textarea').val('');
                }

                $(".ajax_load").fadeOut(200, function () {
                    Swal.fire({title: res.title, text: res.message, icon: res.icon});
                });
            }
        });
    });

    $('.newsletter-form').on('submit', function (e) {
        e.preventDefault();
        var base = $("meta[name=base]").attr("content");

        $.ajax({
            type: "POST",
            url: base + 'newsletter',
            dataType: 'JSON',
            data: $(this).serialize(),
            beforeSend: function () {
                $(".ajax_load").fadeIn(200).css("display", "flex");
            },
            success: function (res) {

                if (res.success) {
                    $('.newsletter-form input[name]').val('');
                }

                $(".ajax_load").fadeOut(200, function () {
                    Swal.fire({title: res.title, text: res.message, icon: res.icon});
                });
            }
        });
    });
})

